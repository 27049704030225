import React from "react";
/* eslint-disable @typescript-eslint/no-explicit-any */
const InstitutionDash = () => {
    return (

        <div className="main-wrapper">
            <div className="main-content">
                <h1>Institution Dashboard</h1>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, vel? Ullam
                    at eaque, voluptatum necessitatibus blanditiis totam voluptatem voluptas ut
                    itaque natus repudiandae a id! Aliquam, tempora? Tenetur,
                    provident voluptatibus.
                </p>

            </div>
        </div>
    )
}

export default InstitutionDash;